<template>
  <form action="" class="login__form">
    <h2 class="login__form-heading">Entrer vos informations</h2>
    <div class="login__form-group">
      <div
        :class="{
          'login__form-item': true,
          'login__form-item--error': v$.email.$errors.length,
        }"
      >
        <input
          class="login__form-item-input"
          type="text"
          placeholder="Email address"
          v-model="user.email"
          @blur="v$.email.$touch"
        />
      </div>
      <div
        class="login__form-item-error"
        v-for="(error, index) of v$.email.$errors"
        :key="index"
      >
        {{ error.$message }}
      </div>
    </div>
    <div class="login__form-group">
      <div
        :class="{
          'login__form-item': true,
          'login__form-item--error': v$.password.$errors.length,
        }"
      >
        <input
          class="login__form-item-input"
          type="password"
          placeholder="Password"
          v-model="user.password"
          @blur="v$.password.$touch"
        />
      </div>
      <div
        class="login__form-item-error"
        v-for="(error, index) of v$.password.$errors"
        :key="index"
      >
        {{ error.$message }}
      </div>
    </div>
    <div class="login__form-errors">
      <p
        class="login__form-error"
        v-for="(error, index) in serverErrors"
        :key="index"
      >
        {{ error }}
      </p>
    </div>
    <atom-link
      class="login__form-link"
      variant="primary"
      :to="{ name: 'RequestResetPassword' }"
    >
      Mot de passe oublié ?
    </atom-link>
    <atom-button
      class="login__form-button"
      variant="primary md full"
      @click.prevent="loginUser"
    >
      Se Connecter
    </atom-button>
    <atom-link
      class="login__form-link login__form-link--centered"
      variant="primary"
      :to="{ name: 'Register' }"
    >
      Créer un compte
    </atom-link>
  </form>
  <div class="login__quote">
    <h1 class="login__quote-content">
      "Un manque de transparence se traduit par la méfiance et un profond sentiment d'insécurité."
    </h1>
    <span class="login__quote-author"
      >- Dalai Lama, The Telegraph, 13 Mai 2012.</span
    >
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const user = ref({
  email: "",
  password: "",
});
const serverErrors = ref([]);
// Validation rules
const rules = {
  email: { required, email },
  password: { required },
};
const v$ = useVuelidate(rules, user);
// Login user function
const loginUser = async () => {
  serverErrors.value = [];
  try {
    const loggedUser = await store.dispatch("auth/loginUser", user.value);
    // Redirect user to forum home page
    setTimeout(() => {}, 1000);
  } catch (err) {
    const { data:{message}, status } = err.response;
    if(status == 401) serverErrors.value.push("Email ou mot de passe incorrecte.");
    else serverErrors.value.push(message);
  }
  router.push({ name: "ForumHome" });
};
</script>

<style lang="scss" scoped>
.login {
  &__form {
    @apply w-full max-w-[500px] bg-white
      py-5 px-2 lg:px-[80px]
      rounded-md;
    &-heading {
      @apply w-full
         text-center text-base font-medium 
         pb-[43px] mb-[43px]
         border-b border-black;
    }
    &-group {
      @apply relative flex mb-1;
    }
    &-item {
      @apply mb-5 w-full;
      &--error {
        .login__form-item {
          &-input {
            @apply border-red-600 border-2 
              focus:ring-0;
          }
        }
      }
      &-notice {
        @apply text-[10px] text-[#C4C4C4] inline-block mb-[3px];
      }
      &-input {
        @apply relative w-full py-2 px-4 border border-gray-100 placeholder-gray-base rounded-md;
      }
      &-error {
        @apply absolute bottom-[3px] right-0 text-xs text-red-600;
      }
    }
    &-button {
      @apply mb-5 mt-5;
    }
    &-link {
      &--centered {
        @apply w-full text-center;
      }
    }
    &-errors {
      @apply w-full relative -top-4;
    }
    &-error {
      @apply text-red-600 text-sm;
    }
  }
  &__quote {
    @apply hidden lg:block p-5 bg-black/40 max-w-[600px] w-full;
    backdrop-filter:blur(12px);
    &-content {
      @apply text-white font-medium 
        text-[30px] xl:text-[36px];
    }
    &-author {
      @apply text-white font-medium text-sm;
    }
  }
}
</style>
